import * as React from "react"
import { Container } from "react-bootstrap"
import { Link} from "@reach/router";

// Components
import Divider from "../Divider";
// import { menuLinks } from "../common/menuUtils";

// Styles
import "./BreadCrumbs.scss"

const BreadCrumbs = ( { breadCrumbsData = [], menu_data } ) => {

    //console.log("Menu_Data", menu_data);

    return (
        <Container>
        <div className="bread-crumbs-wrapper">
      
                <div className="bread-crumbs-content">
                    {breadCrumbsData.length > 0 && breadCrumbsData.filter(item => item.name).map((item, index) => {
                        return(
                            <>
                                {item.link ? 
                                    <Link to={item.link}>
                                        {item.name === "Home"?  <i className="icon icon-home"></i> :item.name}
                                    </Link>
                                : 
                                    <a href={"javascript:;"}>
                                        {item.name}
                                    </a>
                                }                                
                                {index < (breadCrumbsData.filter(item => item.name).length - 1) && <Divider delimiter="/" /> }
                            </>
                        )}
                    )}


                </div>
         
        </div>
        </Container>
    )
}

export default BreadCrumbs